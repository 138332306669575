import { color } from 'd3';
import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import './newBreadCrumb.scss';
import { useAppContextDetails } from '../../../context/AppContext';

const NewBreadcrumb = ({ data, handleBreadCrumb }) => {
  const {
    currentStep,
    setCurrentStep,
    setSelectedTab,
    newWholeValue,
    setNewWholeValue,
    setEachCardShowValue,
    fundingCurrency,
    setFundingCurrency,
    payMethod,
    setPayMethod,
    otcDesk,
    setOtcDesk,
    enterAmount,
    setEnterAmount,
    setOtcApiValue,
    setPayMethodApiValue,
    setFromCurrencyData,
    setCountryApiValue,
    setAddApiValue,
    setToCurrencyApiValue,
  } = useCoinContextData();

  const { textColor } = useAppContextDetails();

  function switchBreadCrumb(event) {
    if (currentStep == 'step4') {
      setEachCardShowValue('step3');
      setOtcDesk(!otcDesk);
      setNewWholeValue({ ...newWholeValue, step3: '', step4: '' });
      setPayMethodApiValue('');
    } else if (currentStep == 'step3') {
      setEachCardShowValue('step2');
      setPayMethod(!payMethod);
      setNewWholeValue({ ...newWholeValue, step2: '', step3: '', step4: '' });
      setCountryApiValue('');
    } else if (currentStep == 'step2') {
      setEachCardShowValue('step1');
      setFundingCurrency(!fundingCurrency);
      setNewWholeValue({ ...newWholeValue, step2: '', step3: '', step4: '' });
      setAddApiValue('');
    } else {
      setEnterAmount(!enterAmount);
    }

    let selectedText = event.target.textContent;
    if (selectedText.includes('Add') || selectedText.includes('Actions')) {
      handleBreadCrumb();
      setAddApiValue('');
      setToCurrencyApiValue('');
      setCountryApiValue('');
      setPayMethodApiValue('');
      setOtcApiValue('');
      setEachCardShowValue({});
      setNewWholeValue({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
      });
      setFromCurrencyData({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
      });
    } else if (selectedText.includes('Sending Currency')) {
      setCurrentStep('step1');
      setSelectedTab('sendingCurrency');
    } else if (selectedText.includes('Country')) {
      setCurrentStep('step2');
      setSelectedTab('fundingCurrency');
    } else if (selectedText.includes('Payment Method')) {
      setCurrentStep('step3');
      setSelectedTab('payMethod');
    } else if (selectedText.includes('OTCDesk')) {
      setCurrentStep('step4');
      setSelectedTab('otcDesk');
    } else {
      setCurrentStep('step5');
      setSelectedTab('enterAmount');
    }
  }
  return (
    <div className="newBreadcrumb">
      {data.map((d, i) => {
        return (
          <p
            key={i}
            style={{
              marginTop: '5px',
              fontWeight: data.length - 1 == i ? '700' : '',
              fontSize: '11px',
              cursor: 'pointer',
              background: data.length - 1 == i ? '#9FD6DF' : '',
              color: data.length - 1 == i ? '#ffffff' : textColor,
              opacity: data.length - 1 == i ? '1' : '0.5',
            }}
            id={d.clickable}
            onClick={(e) => switchBreadCrumb(e)}
          >
            <span
              //   style={{
              //     textDecoration: data.length - 1 == i ? 'underline' : 'none',
              //     textUnderlineOffset: data.length - 1 == i ? '3px' : '0px',
              //   }}
              id={d.clickable}
            >
              {d.value}
            </span>
          </p>
        );
      })}
    </div>
  );
};

export default NewBreadcrumb;
