import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';
import {
  formatterHelper,
  roundHelper,
  usdValueFormatter,
} from '../../../utils/FunctionTools';

import pasteIcon from '../../../static/images/paste.svg';
import priceLockInsurance from '../../../static/images/mobile/priceLockInsurance.svg';
import info from '../../../static/images/mobile/info.svg';
import time from '../../../static/images/mobile/time.svg';
import { BankContext } from '../../../context/Context';

const WithdrawCryptoStepThree = ({ addressInput, withdrawalDetails }) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow, textColor } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [isCopied, setIsCopied] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isCryptoFocused, setIsCryptoFocused] = useState(false);
  const [fiatInput, setFiatInput] = useState('');
  const [cryptoInput, setCryptoInput] = useState('');
  const [activeCoinBalance, setActiveCoinBalance] = useState('');
  const [isPriceLockEnabled, setIsPriceLockEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 130,
      }}
    >
      <div className="breadcrumb">
        <span onClick={(e) => setActionFlow(null)}>Enter Amount</span>
        &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
        <span style={{ fontWeight: 700 }}>Confirmation</span>
      </div>
      <div
        style={{
          padding: '0px 22px',
          overflowY: 'scroll',
          height: '80vh',
          paddingBottom: '20px',
        }}
      >
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '22px',
              paddingBottom: '31px',
            }}
          >
            Step 3: Confirmation
          </div>
          <div>
            Please confirm that all the following details are correct by
            clicking the terms and conditions.
          </div>
        </div>

        {/* Debiting App */}
        <div>
          <div className="cardlabel">Debiting App</div>
          <div className="cardStyle">
            <img
              src={withdrawalDetails?.appData?.app_icon}
              // src={selectedCurrency?.coinImage}
              className="cardImage"
            />
            <div className="cardText">
              {withdrawalDetails?.appData?.app_name}
              {/* {selectedCurrency?.coinName} */}
            </div>
          </div>
        </div>
        {/* Debiting Vault */}
        <div>
          <div className="cardlabel">Debiting Vault</div>
          <div className="cardStyle">
            <img
              src={withdrawalDetails?.coinsData?.fromCoinData.icon}
              // src={selectedCurrency?.coinImage}
              className="cardImage"
            />
            <div className="cardText">
              {withdrawalDetails?.coinsData?.fromCoinData.name}
              {/* {selectedCurrency?.coinName} */}
            </div>
          </div>
        </div>

        {/* Destination */}
        <div>
          <div className="cardlabel">Destination</div>
          <div className="cardStyle">
            <img
              className="cardImage"
              src={withdrawalDetails?.coinsData?.fromCoinData?.icon}
              // src={selectedCurrency?.coinImage}
            />
            <div>
              <div className="cardText">
                External {withdrawalDetails?.coinsData?.fromCoinData?.coin}{' '}
                Address
                {/* External {selectedCurrency?.coinSymbol} Address */}
              </div>
              <div className="cardTextSub">
                {addressInput}
                {/* aajbdjhbewhjdbwej */}
              </div>
            </div>
          </div>
        </div>
        {/* Debiting Amount */}
        <div>
          <div className="cardlabel">Debiting Amount</div>
          <div className="cardStyle1">
            <div className="inCardWrapper">
              <div className="debitAmountStyle">
                {withdrawalDetails?.finalFromAmount}
              </div>
              <div className="inCardCurrency">
                <img
                  className="coinImageStyle"
                  src={selectedCurrency?.coinImage}
                />
                <div className="coinSymbolStyle">
                  {selectedCurrency.coinSymbol}
                </div>
              </div>
            </div>
            <div className="inCardWrapper1">
              <div className="debitAmountStyle">
                {(
                  withdrawalDetails?.finalFromAmount *
                  selectedCurrency?.price?.USD
                ).toFixed(2)}
              </div>
              <div className="inCardCurrency">
                <div className="coinSymbolStyle">USD</div>
                {/* <RightArrow width={6} height={6} /> */}
                {`>`}
              </div>
            </div>
          </div>
        </div>

        {/* Timestamp */}
        <div style={{ paddingHorizontal: 10, paddingTop: 21 }}>
          <div className="labelStyle">
            Exchange Rate Booked At
            <div className="addressText">
              {new Date(withdrawalDetails.currentTimestamp).toUTCString()}
            </div>
          </div>
        </div>
        {/* price lock insurance */}
        <div>
          <div className="cardlabel">PriceLock Insurance</div>
          <div className="cardStyle">
            <img src={priceLockInsurance} alt="" className="cardImage" />
            {/* <PriceLockInsurance width={21} height={21} /> */}
            {/* <Image source={priceLockInsurance} style={styles.cardImage} /> */}
            <div className="cardText">
              Disabled
              {/* {priceLock ? 'Enabled' : 'Disabled'} */}
            </div>
          </div>
        </div>

        {/* Fees */}

        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="cardlabel">Fees</div>
            <div className="cardlabel">
              {withdrawalDetails?.reducedAmount?.toFixed(2)}{' '}
              {withdrawalDetails?.fees_in_coin} (
              {withdrawalDetails?.reducedAmount?.toFixed(2)}%)
            </div>
          </div>

          <div className="cardStyle1">
            <div className="inCardWrapper">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div className="feeLabelStyle">Affiliate</div>
                <img src={info} alt="" className="coinImageStyle" />
                {/* <Image source={info} style={styles.coinImageStyle} /> */}
              </div>
              <div className="feeLabelStyle">
                {withdrawalDetails?.brokerData?.fee.toFixed(4)} (
                {withdrawalDetails?.brokerData?.broker_fee_percentage.toFixed(
                  2
                )}
                %)
              </div>
            </div>
            <div className="inCardWrapper">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div className="feeLabelStyle">App</div>
                <img src={info} alt="" className="coinImageStyle" />
                {/* <Info width={13} height={13} /> */}
              </div>
              <div className="feeLabelStyle">
                {withdrawalDetails?.appData?.app_fee?.toFixed(4)} (
                {withdrawalDetails?.appData?.app_fee_percentage?.toFixed(2)}%)
              </div>
            </div>
            <div className="inCardWrapper">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div className="feeLabelStyle">Banker</div>
                <img src={info} alt="" className="coinImageStyle" />
                {/* <Info width={13} height={13} /> */}
              </div>
              <div className="feeLabelStyle">
                {withdrawalDetails.bankerData.trade_fee_native.toFixed(4)} (
                {withdrawalDetails.bankerData.banker_fee_percentage.toFixed(2)}
                %)
              </div>
            </div>
          </div>
        </div>

        {/* Receiving Amount */}
        <div>
          <div className="cardlabel">Receiving Amount</div>
          <div className="cardStyle1">
            <div className="inCardWrapper">
              <div className="debitAmountStyle">
                {withdrawalDetails.finalToAmount.toFixed(4)}
              </div>
              <div className="inCardCurrency">
                <img
                  src={selectedCurrency.coinImage}
                  className="coinImageStyle"
                />
                <div className="coinSymbolStyle">
                  {selectedCurrency.coinSymbol}
                </div>
              </div>
            </div>
            <div className="inCardWrapper1">
              <div className="debitAmountStyle">
                {(
                  withdrawalDetails.finalToAmount * selectedCurrency?.price?.USD
                ).toFixed(2)}
              </div>
              <div className="inCardCurrency">
                {/* <Image
                    source={{uri: selectedCurrency.coinImage}}
                    style={styles.coinImageStyle}
                  /> */}
                <div className="coinSymbolStyle">USD</div>
                {`>`}
                {/* <RightArrow width={6} height={6} /> */}
                {/* <Image source={smallArrRight} style={styles.arrowStyle} /> */}
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingHorizontal: 10, paddingTop: 21 }}>
          <div
            style={{
              lineHeight: '25px',
              color: textColor,
              fontSize: '12px',
            }}
          >
            Exchange Rate Booked At{' '}
            <div className="addressText">
              {new Date(withdrawalDetails.currentTimestamp).toUTCString()}
            </div>{' '}
            - Since You Have Not Turned On PriceLock Insurance Then The INR
            Valuation Of Your Bitcoin Will Be Different Upon Arrival.
          </div>
        </div>

        {/* Estimated Time Of Arrival */}
        <div>
          <div className="cardlabel">Estimated Time Of Arrival</div>
          <div className="cardStyle">
            {/* <Time width={16} height={22} /> */}
            <img src={time} alt="" className="cardImage" />
            {/* <Image source={time} style={styles.cardImage} /> */}
            <div style={{ paddingLeft: 6 }}>
              <div className="cardText">
                {new Date(withdrawalDetails.next12hrsTimestamp)
                  .toUTCString()
                  .substring(17, 29)}{' '}
                (12 Hours)
              </div>
              <div className="cardTextSub">
                {new Date(withdrawalDetails.next12hrsTimestamp)
                  .toUTCString()
                  .substring(0, 16)}
              </div>
            </div>
          </div>
        </div>

        {/* Latest Time Of Arrival */}
        <div>
          <div className="cardlabel">Latest Time Of Arrival</div>
          <div className="cardStyle">
            {/* <Time width={16} height={22} /> */}
            <img src={time} alt="" className="cardImage" />
            <div style={{ paddingLeft: 6 }}>
              <div className="cardText">
                {new Date(withdrawalDetails.next24hrsTimestamp)
                  .toUTCString()
                  .substring(17, 29)}{' '}
                (24 Hours)
              </div>
              <div className="cardTextSub">
                {new Date(withdrawalDetails.next24hrsTimestamp)
                  .toUTCString()
                  .substring(0, 16)}
              </div>
            </div>
          </div>
        </div>

        <div className="confirmHeader">Confirmations</div>
        <div className="confirmation">
          <input
            type="checkbox"
            checked={checked1}
            onChange={(e) => setChecked1(e.target.checked)}
          />
          {/* <CheckBox
            style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
            value={check1}
            onValueChange={(newValue) => setCheck1(!check1)}
            boxType="square"
          /> */}
          <div className="confirmationText">
            I Consent To All The Aforementioned Parameters Regarding This
            Transfer Request.
          </div>
        </div>
        <div className="confirmation">
          <input
            type="checkbox"
            checked={checked2}
            onChange={(e) => setChecked2(e.target.checked)}
          />
          {/* <CheckBox
            style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
            value={check2}
            onValueChange={(newValue) => setCheck2(!check2)}
            boxType="square"
          /> */}
          <div className="confirmationText">
            I Acknowledge That CCSWealth Is Not Able To Recover Funds Once They
            Have Sent To
            <div className="addressText">{addressInput}</div>
            {/* <div className="addressText">kjdsbfkjsrktgherlgk</div> */}
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div
          onClick={(e) => {
            if (checked1 && checked2) {
              setActionFlow('WithdrawCryptoStepFour');
            }
          }}
          className="submitButton"
          style={{ background: '#464B4E', width: '100%' }}
        >
          <div style={{ color: 'white', fontWeight: 'bold' }}>Confirm</div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawCryptoStepThree;
