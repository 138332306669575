import React, { useState } from 'react';
import { useEffect, useContext } from 'react';
import Layout from '../../Layout/Layout';
import { Link, useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import './stakings.scss';
import searchIcon from '../../static/images/search.svg';
import vault from '../../static/images/vaults/fulllogo.svg';
import ddown from '../../static/images/malls/ddown.svg';
import dropdown from './dropdown.svg';
import axios from 'axios';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useCoinContextData } from '../../context/CoinContext';
import { useMarketContext } from '../../context/MarketContext';
import { GlobalContext } from '../../context/GlobalContext';
import { NetWorthContext } from '../../context/NetWorthContext';
import { VaultPageContext } from '../../context/VaultPageContext';
// import { VaultPageContext } from '../../context/VaultPageContext';

const Staking = () => {
  const history = useHistory();
  const { selectedContract, setSelectedContract, mmEarningsVault } =
    useAppContextDetails();
  const { email } = useContext(BankContext);
  const [isloading, setIsloading] = useState(true);
  const [contractData, setContractData] = useState([]);
  const [value, setValue] = useState();
  const [coin, setCoin] = useState('');
  const { pathname } = useLocation();
  const { coinType, setCoinType } = useCoinContextData();
  const { trackerDataDeposit } = useContext(VaultPageContext);
  const { netWorth, liquidCrypto, liquidFiat } = useContext(NetWorthContext);

  const { setCoinSelected } = useMarketContext();
  const { setSelectedBondsNav } = useContext(GlobalContext);

  useEffect(() => {
    if (pathname.includes('contracts')) {
      setCoinType('investments');
    }
  }, [pathname]);

  useEffect(() => {
    let contract = localStorage.getItem('staking');
    contract = JSON.parse(contract);
    // console.log(contract._id, 'coinnn');
    let mailId = email;
    if (contract) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/contract/get?email=${mailId}&coin=${contract?._id}`
        )
        .then((response) => {
          let result = response?.data?.icedContracts[0]?.contracts;
          let stakevalue = response?.data?.icedContracts[0]?.currentVoc;
          let stakecoin = response?.data?.icedContracts[0]?._id;
          // console.log(result, 'contract result');
          setContractData(result);
          setValue(stakevalue);
          setCoin(stakecoin);
          setIsloading(false);
        })
        .catch((error) => {
          console.log(error, 'contract error');
        });
    }
  }, []);

  return (
    <Layout active="vaults" className="contracts" hideFooter>
      <div className="contract-container">
        <div className="vaultss-navbar">
          <div className="vaultss-icon">
            <img src={vault} alt="vault" />
          </div>
          <div className="search-vaultss">
            {/* <div className="all">
              <p>All Assets</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                // onChange={(event) => filterItem(event.target.value)}
                // onChange={(event) => {
                //   setSearchedValue(event.target.value);
                // }}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="right-top">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '3rem' }}
              className="divv"
            >
              <div>
                <p style={{ opacity: '0.25' }}>
                  {coinType === 'crypto'
                    ? 'Liquid Crypto'
                    : coinType === 'tracker'
                    ? 'Deposits'
                    : coinType === 'moneyMarkets'
                    ? 'MM Earnings Vaults'
                    : coinType === 'fiat'
                    ? 'Liquid Forex'
                    : 'Liquid Holdings'}
                </p>
                <p style={{ fontWeight: '700' }}>
                  {/* {
                  coinType === 'tracker' && trackerData &&
                  trackerData?.perAppCode?.length > 0
                    ? trackerData?.perAppCode[0]?.count
                    : coinType === 'tracker'
                    ? '0'
                    : ''} */}
                  {coinType === 'crypto'
                    ? `$${liquidCrypto ? liquidCrypto : '0.00'} USD`
                    : coinType === 'fiat'
                    ? `$${liquidFiat ? liquidFiat : '0.00'} USD`
                    : coinType === 'moneyMarkets'
                    ? `$${mmEarningsVault ? mmEarningsVault : '0.00'} USD`
                    : coinType == 'tracker'
                    ? `${trackerDataDeposit ? trackerDataDeposit : '0'}`
                    : '$0.00 USD'}
                </p>
              </div>
            </div>
            <div style={{ paddingLeft: '2rem' }} className="divv">
              <div>
                <p style={{ opacity: '0.25' }}>
                  {coinType === 'tracker' ? 'Withdrawals' : 'Total Net-Worth'}
                </p>
                <p style={{ fontWeight: '700' }}>
                  {coinType === 'tracker'
                    ? '0'
                    : `$${netWorth ? netWorth : '0.00'} USD`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '100%' }}>
            <div className="optionss-divider">
              <div
                className={`each-option ${
                  coinType === 'crypto' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'crypto' ? '650' : '300',
                // }}
                onClick={() => {
                  setCoinType('crypto');
                  history.push('/vault');
                }}
              >
                <div>
                  <p>Crypto</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  coinType === 'fiat' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'fiat' ? '650' : '300',
                // }}
                onClick={() => {
                  setCoinType('fiat');
                  history.push('/vault');
                }}
              >
                <div>
                  <p>Forex</p>
                </div>
              </div>
              {/* <div
                className={`each-option ${
                  coinType === 'bonds' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'bonds' ? '650' : '300',
                // }}
                onClick={() => {
                  setCoinType('bonds');
                  history.push('/vault');
                }}
              >
                <div>
                  <p>Staking Rewards</p>
                </div>
              </div> */}
              <div
                className={`each-option ${
                  coinType === 'moneyMarkets' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'moneyMarkets' ? '650' : '300',
                // }}
                onClick={() => {
                  setCoinType('moneyMarkets');
                  history.push('/vault');
                }}
              >
                <div>
                  <p>MoneyMarket Earnings</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  coinType === 'investments' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'investments' ? '650' : '300',
                // }}
                onClick={() => setCoinType('investments')}
              >
                <div>
                  <p>Investments</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  coinType === 'tracker' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: coinType === 'tracker' ? '650' : '300',
                // }}
                onClick={() => {
                  setCoinType('tracker');
                  history.push('/vault');
                }}
              >
                <div>
                  <p>Tracker</p>
                </div>
              </div>
            </div>
            <div className="last-option"></div>
          </div>
        </div>
        <div className="header-data">
          <div>{coin} Staking Contracts</div>
          <div className="contract-value">{value?.toFixed(5)}</div>
        </div>
        <div className="boxes-container">
          <div className="all-boxes">
            <div className="each-box">
              <div>All Statuses</div>
              <img src={dropdown} alt="" />
            </div>
            <div className="each-box">
              <div>All Apps</div>
              <img src={dropdown} alt="" />
            </div>
            <div className="each-box">
              <div>All Issuers</div>
              <img src={dropdown} alt="" />
            </div>
          </div>
          <div className="search-box">
            <div className="each-box">
              <div>Search Contracts</div>
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="table-header">
          <div style={{ paddingLeft: '2rem' }}>Date</div>
          <div>Contracts</div>
          <div>Investment</div>
          <div>Earnings</div>
          <div>Unpaid Earnings</div>
          <div>Days Till Expiry</div>
        </div>
        <div className="table-data">
          <div className="scroll-div">
            {isloading
              ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="table-card" key={index}>
                        <div
                          className="date-val"
                          style={{ paddingLeft: '2rem' }}
                        >
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div
                          className="hash-btn"
                          style={{ background: '#ffffff' }}
                        >
                          <Skeleton width={75} height={25} />
                        </div>
                      </div>
                    );
                  })
              : contractData?.map((e, i) => {
                  return (
                    <div className="table-card" key={i}>
                      <div className="date-val" style={{ paddingLeft: '2rem' }}>
                        {moment(e?.original_date).format('MMM Do YYYY')}
                      </div>
                      <div className="date-val">{e?.num_of_bonds}</div>
                      <div className="date-val">{e?.investment}</div>
                      <div className="date-val">
                        {e?.completed_stats?.earnings?.toFixed(3)}
                      </div>
                      <div className="date-val">
                        {e?.remaining_stats?.earnings?.toFixed(3)}
                      </div>
                      <div className="date-val">{e?.remaining_stats?.days}</div>
                      <Link
                        className="hash-btn"
                        to={`/defi/explorer/${e?._id}`}
                        onClick={() => {
                          setCoinSelected('defihash');
                          setSelectedBondsNav('Stats');
                        }}
                      >
                        Hash
                      </Link>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Staking;
