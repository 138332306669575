import React from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { handleDecimal } from '../../../utils/FunctionTools';
import picelock from '../../../static/images/picelock.svg';

export const EnterAmount = ({
  handleBreadCrumb,
  validateText,
  isValueLoading,
  check,
  setCheck,
  handleCurrencyConversion,
}) => {
  const {
    addressTerm,
    selectedCoin,
    fromTerm,
    toTerm,
    setFromTerm,
    setInitiateWithdraw,
    setStep,
    setCoinAction,
    setSelectedCoin,
    setCoinActionEnabled,
    setAddressTerm,
  } = useCoinContextData();
  const { appCurrencySymbol, appCurrencyName, textColor, appColorCode } =
    useAppContextDetails();

  const handlePercCal = (value) => {
    let balance = handleDecimal(selectedCoin.coinValue);
    let final = (balance * value) / 100;
    final = handleDecimal(final);
    setFromTerm(final);
    handleCurrencyConversion(final);
  };

  return (
    <div className="sidebarwithdraw" style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <div
          className="breadcrumb"
          style={{
            display: 'flex',
            padding: '5px 0 5px 1.85rem',
            height: '4%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            alignItems: 'center',
            width: '100%',
            borderRadius: '0px',
            minHeight: '38px',
            cursor: 'default',
            marginBottom: '0',
            gap: '1rem',
          }}
        >
          <p
            className="breadCrumbText"
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleBreadCrumb}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: textColor,
                fontWeight: '500',
                border: '0.5px solid #e5e5e5',
              }}
            >
              Destination
            </span>
          </p>
          <p
            className="active-crumb"
            style={{ height: '100%', marginTop: '0' }}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#fff',
                fontWeight: '500',
                background: appColorCode,
              }}
            >
              Amount
            </span>
          </p>
        </div>
        <div
          className="step-div"
          style={{
            padding: '0',
            width: '100%',
            height: 'calc(100% - 4%)',
            position: 'relative',
          }}
        >
          <p
            className="step-para"
            style={{
              margin: '0',
              paddingLeft: '1.85rem',
              height: '5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Enter Amount
          </p>
          <div className="step2-div">
            <div
              style={{
                width: '100%',
                height: '6.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '1rem',
                borderTop: '0.5px solid #e5e5e5',
                borderBottom: '0.5px solid #e5e5e5',
              }}
            >
              <input
                style={{
                  width: '60%',
                  height: '100%',
                  borderRadius: '0',
                  border: 'none',
                  fontWeight: '500',
                  padding: '0 0 0 1.85rem',
                  fontSize: '1.5rem',
                }}
                className="address-input"
                placeholder="0.00"
                key="from-1"
                id="from-1"
                type="text"
                value={fromTerm}
                onChange={(e) => validateText(e.target.value)}
              />
              <div
                style={{
                  padding: '5px 1.5rem',
                  border: '1px solid #EDEDED',
                  borderRadius: '35px',
                  cursor: 'pointer',
                  fontWeight: '500',
                }}
                onClick={() => handlePercCal(100)}
              >
                100%
              </div>
            </div>
            <div className="picelock" style={{ padding: '0.5rem 1.85rem 0' }}>
              <img src={picelock} alt="" />
              <div
                className="d-flex"
                style={{
                  width: '94%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p
                  className="pice-text ml-3 mt-3"
                  style={{ fontSize: '1rem', fontWeight: '100' }}
                >
                  Enable PiceLock Insurance
                </p>
                <div
                  className="withdraw-toggle"
                  style={{ marginTop: '-14px', marginLeft: '0' }}
                >
                  <input
                    className="withdraw-tgl1"
                    type="checkbox"
                    id="switch"
                    checked={check}
                    onClick={(e) => {
                      const { checked } = e.target;
                      setCheck(checked);
                    }}
                  />
                  <label className="withdraw-tgl2" htmlFor="switch"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="w-nextBtn">
            <div
              style={{
                opacity: fromTerm !== '' ? 1 : 0.25,
                cursor: fromTerm !== '' ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (fromTerm !== '') {
                  setStep(3);
                  setInitiateWithdraw(true);
                }
              }}
            >
              Next Step
            </div>
          </div>
          <div className="withdrawFooter">
            <div className="backclose-btns">
              <div
                style={{
                  color: textColor,
                  border: '1px solid #E5E5E5',
                }}
                onClick={() => {
                  handleBreadCrumb();
                }}
              >
                Back
              </div>
              <div
                style={{
                  background: '#FF5E5E',
                }}
                onClick={() => {
                  setCoinAction(['Menu']);
                  setSelectedCoin('');
                  setCoinActionEnabled(false);
                  setAddressTerm('');
                  setFromTerm('');
                  setStep(1);
                }}
              >
                Close
              </div>
            </div>
            <div
              className="action-btn"
              style={{
                background: appColorCode,
              }}
              onClick={() => {
                setCoinAction(['Menu']);
                setAddressTerm('');
                setFromTerm('');
                setStep(1);
              }}
            >
              {selectedCoin?.coinSymbol} Action
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
