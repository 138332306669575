import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useGetPathId, useLoadQuote } from './api';
import { ForexBreadCrumbs } from './ConditionalForexComponents';
import { useHistory } from 'react-router-dom';

export const ConfirmQuote = () => {
  const {
    selectedCoin,
    addApiValue,
    addForexCurrencyValue,
    payMethodApiValue,
    bankerId,
    confirmQuote,
    setConfirmQuote,
    addApiValueCoin,
    setIsAddingForexLoading,
    setCoinActionEnabled,
    setCoinAction,
    setFundingCurrency,
    setPayMethod,
    setOtcDesk,
    setEnterAmount,
    setCurrentStep,
    setSelectedTab,
    setAddApiValue,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setOtcApiValue,
    setEachCardShowValue,
    setNewWholeValue,
    newWholeValue,
    senbtnClicked,
    setSendBtnClicked,
    setAddForexCurrencyValue,
    finalToAmountValue,
    setFinalToAmountValue,
  } = useCoinContextData();
  const { appCode, appLogo, appFullLogo, textColor } = useAppContextDetails();
  const { email, sidebarCollapse, admin } = useContext(BankContext);
  const [pathIdParams, setPathIdParams] = useState({});
  const history = useHistory();

  const userToken = Cookies.get(APP_USER_TOKEN);

  // const { data: pathIdResponse } = useGetPathId(pathIdParams, setConfirmQuote);
  // const pathId = pathIdResponse?.data?.paths?.[0]?.path_id;

  // const {
  //   mutate: getQuote,
  //   data: quoteResponse,
  //   isLoading: isQuoteLoading,
  // } = useLoadQuote();
  // const { data: userApps } = useUserApps(email);
  // const selectedUserApp = userApps?.filter((e) => e.app_code === appCode);
  // const userAppsProfileId = selectedUserApp?.[0]?.profile_id;
  // const finalAmount = quoteResponse?.data?.finalToAmount;

  // useEffect(() => {
  //   setPathIdParams({
  //     select_type: 'fund',
  //     to_currency: selectedCoin.coinSymbol,
  //     from_currency: addApiValue.value,
  //     paymentMethod: payMethodApiValue.value,
  //     banker: bankerId,
  //   });
  // }, []);

  // useEffect(() => {
  //   if (confirmQuote && pathId && userAppsProfileId) {
  //     getQuote({
  //       token: userToken,
  //       email,
  //       app_code: appCode,
  //       profile_id: userAppsProfileId,
  //       coin_purchased: selectedCoin.coinSymbol,
  //       purchased_from: addApiValue.value,
  //       from_amount: addForexCurrencyValue,
  //       stats: true,
  //       identifier: `Add ${addForexCurrencyValue} ${addApiValue.value} Via ${payMethodApiValue.value}`,
  //       path_id: pathId,
  //     });
  //   }
  // }, [confirmQuote, pathId, userAppsProfileId]);

  // const isLoading = isQuoteLoading && !finalAmount && finalAmount !== 0;

  // useEffect(() => {
  //   setIsAddingForexLoading(isLoading);
  // }, [isLoading]);

  return (
    <>
      <div
        className="newSidebar"
        // style={{
        //   opacity: isLoading ? 0.25 : 1,
        // }}
      >
        <div className="top-text">
          <img
            className="clickable"
            src={sidebarCollapse ? appLogo : appFullLogo}
            style={{ width: '80%', height: '80%' }}
            onClick={() => {
              if (admin) {
                // setAdminSidebar(true);
                // setChatOn(false);
                console.log('admin');
              } else {
                history.push('/app');
                setCoinAction(['Menu']);
                setCoinActionEnabled(false);
              }
            }}
          />
        </div>
        <div className="new-breadcrumb">
          <ForexBreadCrumbs />
        </div>
        <div className="sending-text">
          {/* Sending Via {payMethodApiValue.value} */}
          You Will Receive
        </div>
        <div
          className="add-amount-box"
          style={{
            padding: '0 1.85rem',
            width: '100%',
            borderBottom: '0.5px solid #e5e5e5',
            marginLeft: '0',
            marginTop: '0',
            borderRadius: '0px',
            borderTop: 'none',
            borderRight: 'none',
            height: '5rem',
          }}
        >
          <div className="div-amount-input1">
            <div
              className="add-from-coin"
              key="add"
              style={{ justifyContent: 'flex-start', borderRight: 'none' }}
            >
              <img
                className="coinimgsmall"
                src={selectedCoin.coinImage}
                alt=""
                style={{ width: '25px', height: '25px' }}
              />
              <p
                className="coinsymbolsmall"
                style={{ fontSize: '1.5rem', fontWeight: '600' }}
              >
                {selectedCoin.coinSymbol}
              </p>
            </div>
            <input
              className="add-input"
              style={{
                fontSize: '1.5rem',
                fontWeight: '400',
              }}
              placeholder="0.00"
              key="add"
              id="add"
              type="number"
              value={finalToAmountValue?.toFixed(2)}
              disabled
            />
          </div>
        </div>

        <div
          className="newbtns-div"
          style={{ borderTop: '0.5px solid #e5e5e5' }}
        >
          <div className="top-btns">
            <div
              style={{
                background: 'white',
                color: textColor,
                fontWeight: '500',
                border: '1px solid #E5E5E5',
                borderRadius: '35px'
              }}
              onClick={() => {
                setAddForexCurrencyValue('');
                setCurrentStep('step5');
                setSelectedTab('enterAmount');
              }}
            >
              Back
            </div>
            <div
              style={{ background: '#FF5E5E', fontWeight: '500', borderRadius: '35px' }}
              onClick={() => {
                setCoinActionEnabled(false);
                setCoinAction(['Menu']);
                setFundingCurrency(false);
                setPayMethod(false);
                setOtcDesk(false);
                setEnterAmount(false);
                setCurrentStep('step1');
                setSelectedTab('sendingCurrency');
                setAddApiValue('');
                setToCurrencyApiValue('');
                setCountryApiValue('');
                setPayMethodApiValue('');
                setOtcApiValue('');
                setAddForexCurrencyValue('');
                setEachCardShowValue('step1');
                setNewWholeValue({
                  ...newWholeValue,
                  step2: '',
                  step3: '',
                  step4: '',
                });
                setSendBtnClicked('');
              }}
            >
              Close
            </div>
          </div>
          <div
            style={{ background: 'linear-gradient(270deg,#81cbd7,#3d4394)', fontWeight: '500', borderRadius: '35px' }}
            className="bottom-btn"
            onClick={() => {
              setCoinAction(['Menu']);
              setFundingCurrency(false);
              setPayMethod(false);
              setOtcDesk(false);
              setEnterAmount(false);
              setCurrentStep('step1');
              setSelectedTab('sendingCurrency');
              setAddApiValue('');
              setToCurrencyApiValue('');
              setCountryApiValue('');
              setPayMethodApiValue('');
              setOtcApiValue('');
              setEachCardShowValue('step1');
              setNewWholeValue({
                ...newWholeValue,
                step2: '',
                step3: '',
                step4: '',
              });
              setSendBtnClicked('');
            }}
          >
            {selectedCoin?.coinSymbol ? selectedCoin?.coinSymbol : 'CAD'}{' '}
            Actions
          </div>
        </div>
      </div>
      {/* {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )} */}
    </>
  );
};
