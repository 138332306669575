import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../context/AppContext';

import './listComponent.scss';
import searchIcon from '../../static/images/search.svg';
import vault from '../../static/images/vaults/fulllogo.svg';

import { useCoinContextData } from '../../context/CoinContext';

// import Investments from './Investments';
import Toggle from '../../components/Toggle';
import { TrackerContents } from '../VaultsPage/VaultComponent/TrackerContents';

import { TrackerDetailed } from '../VaultsPage/VaultComponent/TrackerDetailed';
import Vaults from '../Vaults';
import { TrackerContentsWithdraw } from '../VaultsPage/VaultComponent/TrackerContentsWithdraw';

export const ListComponent = ({
  isLoading,
  leftHeaderData,
  leftSubHeader,
  rightHeaderData,
  searchedValue,
  setSearchedValue,
}) => {
  const { appColorCode, balanceToggle, setBalanceToggle, mmEarningsVault } =
    useAppContextDetails();

  const { setCoinType, coinType, trackerType, setTrackerType } =
    useCoinContextData();
  const isBanking = window.location.pathname === '/banking';

  return (
    <>
      <div className="list-component">
        {isBanking ? (
          <div
            className="userdata"
            style={{
              backgroundColor: appColorCode,
            }}
          >
            <div
              className="left-data"
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
            >
              {isLoading ? (
                <Skeleton className="data-loader" />
              ) : (
                leftHeaderData
              )}
              {leftSubHeader}
            </div>
            <div
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
              className="right-data"
            >
              {rightHeaderData &&
                (isLoading ? (
                  <Skeleton className="data-loader" />
                ) : (
                  rightHeaderData
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="vaultss-navbar">
              <div className="vaultss-icon">
                <img src={vault} alt="vault" />
              </div>
              <div className="search-vaultss">
                <div className="im">
                  <input
                    type="text"
                    placeholder="Im Looking For..."
                    // onChange={(event) => filterItem(event.target.value)}
                    onChange={(event) => {
                      setSearchedValue(event.target.value);
                    }}
                  />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                style={{ background: appColorCode }}
                className="trackerBtn"
                onClick={() => setCoinType('tracker')}
              >
                Tracker
              </div>
            </div>
            <div className="w-space">
              <div className="optionss" style={{ width: '100%' }}>
                <div className="optionss-divider">
                  <div
                    className={`each-option ${
                      coinType === 'crypto' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('crypto')}
                  >
                    <div>
                      <p>Crypto</p>
                    </div>
                  </div>
                  <div
                    className={`each-option ${
                      coinType === 'fiat' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('fiat')}
                  >
                    <div>
                      <p>Forex</p>
                    </div>
                  </div>
                  {/* <div
                    className={`each-option ${
                      coinType === 'bonds' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('bonds')}
                  >
                    <div>
                      <p>Staking</p>
                    </div>
                  </div> */}
                  <div
                    className={`each-option ${
                      coinType === 'moneyMarkets' ? 'each-option-selected' : ''
                    }`}
                    onClick={() => setCoinType('moneyMarkets')}
                  >
                    <div>
                      <p>MoneyMarkets</p>
                    </div>
                  </div>
                </div>
                <div className="last-option">
                  <div className="toggle">
                    {coinType === 'tracker' ? (
                      <Toggle
                        toggle={trackerType}
                        setToggle={setTrackerType}
                        coinType={coinType}
                      />
                    ) : (
                      <Toggle
                        toggle={balanceToggle}
                        setToggle={setBalanceToggle}
                        coinType={coinType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {coinType === 'tracker' && trackerType == 'withdraw' ? (
          <TrackerContentsWithdraw />
        ) : coinType === 'tracker' ? (
          <TrackerContents />
        ) : coinType === 'trackerdetailed' ? (
          <TrackerDetailed />
        ) : (
          coinType != 'investments' && <Vaults searchedValue={searchedValue} />
        )}
      </div>
    </>
  );
};
