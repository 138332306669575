import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { AirMenu, SIDEBAR_CONTENTS, AirToken } from './constants';
import { useLocation } from 'react-router-dom';

//images
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import guest from '../../static/images/guest.jpg';

import digifinex from '../../static/images/icons/digifinex.svg';
import coinMarketCap from '../../static/images/icons/coinMarketCap.svg';
import etherScan from '../../static/images/icons/etherScan.svg';
import dgp from '../../static/images/icons/dgp.svg';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { openAppRetiredApp } from '../../assets/Functions/miniFunctions';

export const DefaultSideBarContent = ({
  active,
  sidebarCollapse,
  chatOn,
  setChatOn,
  appColorCode,
  openSettings,
  isSecondaryMenu,
  toggleSettings,
}) => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
  } = useContext(BankContext);

  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    isClose,
    setIsClose,
    setSelectedCoin,
    setCoinType,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const { appCurrencySymbol, setCoinSelected } = useAppContextDetails();

  const { pathname } = useLocation();
  const history = useHistory();
  // const sideBarBorder = { borderLeft: `7px solid ${appColorCode}` };
  const sideBarBorder = {
    background: '#FFFFFF',
    border: ' 0.5px solid #E5E5E5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    height: 'max-content',
    aspectRatio: '1/1',
    margin: '2rem 1rem',
  };
  const applyStyle = (condition) => (condition ? sideBarBorder : {});
  const closeChatScreen = () => (chatOn ? setChatOn(false) : null);
  const [selectedTab, setSelectedTab] = useState('fund');
  const [defaultData, setDefaultData] = useState('default');
  const [hoverItem, setHoverItem] = useState('');
  const [tempData, setTempData] = useState([]);
  const [tempDataCad, setTempDataCad] = useState([]);
  const bgColor = { background: '#D9F5F6' };
  const applybgColor = (condition) => (condition ? bgColor : {});

  function hoverFunctionPortfolio() {
    setHoverItem('networth');
  }

  function hoverFunctionAir() {
    setHoverItem('air');
  }

  function hoverFunctionMalls() {
    setHoverItem('malls');
  }

  function hoverFunctionAcademy() {
    setHoverItem('academy');
  }

  function hoverFunctionVaults() {
    setHoverItem('vaults');
  }

  function hoverFunctionSwap() {
    setHoverItem('swap');
  }

  function hoverFunctionMarket() {
    setHoverItem('market');
  }

  function hoverFunctionMarkets() {
    setHoverItem('markets');
  }

  function hoverFunctionAffiliate() {
    setHoverItem('affiliate');
  }

  function hoverFunctionFunds() {
    setHoverItem('funds');
  }
  function hoverFunctionAssets() {
    setHoverItem('assets');
  }
  function hoverFunctionSignals() {
    setHoverItem('signals');
  }
  function hoverFunctionShares() {
    setHoverItem('shares');
  }

  function hoverLeaveFunc() {
    setHoverItem('');
  }

  // useEffect(() => {
  //   if (pathname.includes('vault')) {
  //     setSelectedTab('fund');
  //   } else {
  //     setSelectedTab('air');
  //   }
  // }, [pathname]);

  useEffect(() => {
    if (pathname.includes('vault')) {
      axios
        .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
          app_code: 'x3',
          email,
          displayCurrency: appCurrencySymbol,
          post_app_prices: true,
          orderby_dsc: true,
          with_balances: false,
          type: 'crypto',
        })
        .then((response) => {
          let result = response?.data?.coins_data;
          let filteredresult = result.filter(
            (e) =>
              e.coinSymbol === 'BTC' ||
              e.coinSymbol === 'USDT' ||
              e.coinSymbol === 'ETH' ||
              e.coinSymbol === 'USDC'
          );
          filteredresult.sort((a, b) => {
            const nameA = a.coinSymbol;
            const nameB = b.coinSymbol;
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setTempData(filteredresult);
        });
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes('vault')) {
      axios
        .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
          app_code: 'x3',
          email,
          displayCurrency: appCurrencySymbol,
          post_app_prices: true,
          orderby_dsc: true,
          with_balances: false,
          type: 'fiat',
        })
        .then((response) => {
          let result = response?.data?.coins_data;
          // console.log(result, 'resulttt');
          let filteredresultCad = result.filter((e) => e.coinSymbol === 'CAD');
          // console.log(filteredresultCad, 'cadd');
          setTempDataCad(filteredresultCad);
        });
    }
  }, [pathname]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: sidebarCollapse ? '' : '45%',
          display: isClose ? 'none' : 'flex',
          flexWrap: sidebarCollapse ? 'nowrap' : 'wrap',
          border: sidebarCollapse ? 'none' : '0.5px solid #EBEBEB',
          borderRight: 'none',
          flexFlow: sidebarCollapse ? 'column' : 'wrap',
          position: sidebarCollapse ? 'absolute' : '',
          top: sidebarCollapse ? '50%' : '',
          left: sidebarCollapse ? '50%' : '',
          transform: sidebarCollapse ? 'translate(-50%, -50%)' : '',
          alignContent: sidebarCollapse ? '' : 'flex-start',
        }}
      >
        {/* <div
          className="menu-itm active clickable"
          style={{
            ...applyStyle(active === 'air' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'air' && active != 'air' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            if (email) {
              history.push('/air');
            } else {
              history.push('/');
            }
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionAir}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[10].icon}
            alt="VaultIcon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'air' || active === 'air'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'air' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[10].name}
            </div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display: hoverItem === 'air' && active != 'air' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[10].name}
          </div>
        </div> */}

        {/* <div
          className="menu-itm active clickable"
          style={{
            ...applyStyle(active === 'networth' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'networth' &&
                active != 'networth' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            if (email) {
              setSelectedTopItemReact('networth');
              history.push('/app');
            } else {
              history.push('/');
            }
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionPortfolio}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[0].icon}
            alt="VaultIcon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'networth' || active === 'networth'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'networth' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[0].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'networth' && active != 'networth'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[0].name}
          </div>
        </div> */}

        {/* <div
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            ...applyStyle(active === 'academy' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'academy' && active != 'academy' && !sidebarCollapse
            ),
            // borderRight: '1px',
          }}
          onClick={() => {
            if (email) {
              setSelectedTopItemReact('Resources');
              history.push('/academy');
            } else {
              history.push('/');
            }
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionAcademy}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[8].icon}
            alt="VaultIcon"
            style={{
              height:
                hoverItem === 'academy' || active === 'academy'
                  ? '50px'
                  : '40px',
            }}
          />
          {!sidebarCollapse && active === 'academy' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[8].name}</div>
          )}
          <div
            className="sidebar-icon-label1"
            style={{
              display:
                hoverItem === 'academy' && active != 'academy'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[8].name}
          </div>
        </div> */}

        <div
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active.includes('vaults') && !chatOn && sidebarCollapse
            ),
            // ...applybgColor(
            //   hoverItem === 'vaults' && active != 'vaults' && !sidebarCollapse
            // ),
          }}
          onClick={() => {
            if (email) {
              history.push('/vault');
            } else {
              history.push('/');
            }
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionVaults}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[1].icon}
            alt="VaultIcon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'vaults' || active === 'vaults'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'vaults' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[1].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'vaults' && active != 'vaults' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[1].name}
          </div>
        </div>

        <Link
          to="/swap"
          className="menu-itm active clickable"
          style={{
            // borderRight: 'none',
            ...applyStyle(active === 'swap' && !chatOn && sidebarCollapse),
            // ...applybgColor(
            //   hoverItem === 'swap' && active != 'swap' && !sidebarCollapse
            // ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionSwap}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[9].icon}
            alt="Swap Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'swap' || active === 'swap'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'swap' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[9].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'swap' && active != 'swap' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[9].name}
          </div>
        </Link>

        {/* <Link
          to="/market"
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            ...applyStyle(active === 'market' && !chatOn && sidebarCollapse),
            // ...applybgColor(
            //   hoverItem === 'market' && active != 'market' && !sidebarCollapse
            // ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionMarket}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[2].icon}
            alt="Markets Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'market' || active === 'market'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'market' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[2].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'market' && active != 'market' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[2].name}
          </div>
        </Link> */}

        {/* <Link
          to="/markets"
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            ...applyStyle(active === 'markets' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'markets' && active != 'markets' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionMarkets}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[14].icon}
            alt="Markets Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'markets' || active === 'market'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'markets' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[14].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'markets' && active != 'markets' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[14].name}
          </div>
        </Link> */}

        {/* <Link
          to="/shares"
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            ...applyStyle(active === 'shares' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'shares' && active != 'shares' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
            setCoinSelected('');
          }}
          onMouseEnter={hoverFunctionShares}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[15].icon}
            alt="Markets Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'shares' || active === 'shares'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'shares' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[15].name}
            </div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'shares' && active != 'shares' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[15].name}
          </div>
        </Link> */}

        <div
          className="menu-itm active clickable"
          style={{
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
            borderRight: 'none',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
            borderBottom: 'none',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
            borderBottom: 'none',
          }}
        ></div>

        <div
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            borderBottom: 'none',
            display: sidebarCollapse ? 'none' : 'flex',
            cursor: 'default',
          }}
        ></div>
        {/* <Link
          to="/affiliate"
          className="menu-itm active clickable"
          style={{
            borderBottom: '0px',
            ...applyStyle(active === 'affiliate' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'affiliate' &&
                active != 'affiliate' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionAffiliate}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[3].icon}
            alt="Affiliate Icon"
            style={{
              height:
                hoverItem === 'affiliate' || active === 'affiliate'
                  ? '50px'
                  : '40px',
            }}
          />
          {!sidebarCollapse && active === 'affiliate' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[3].name}</div>
          )}
          <div
            className="sidebar-icon-label1"
            style={{
              display:
                hoverItem === 'affiliate' && active != 'affiliate'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[3].name}
          </div>
        </Link> */}
        {/*  */}
        {/* <Link
          to="/funds"
          className="menu-itm active clickable"
          style={{
            borderRight: 'none',
            // borderBottom: '0px',
            ...applyStyle(active === 'funds' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'funds' && active != 'funds' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionFunds}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[11].icon}
            alt="funds Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'funds' || active === 'funds'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'funds' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[11].name}
            </div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'funds' && active != 'funds' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[11].name}
          </div>
        </Link> */}

        {/* <Link
          to="/signals"
          className="menu-itm active clickable"
          style={{
            // borderRight: 'none',
            // borderBottom: '0px',
            ...applyStyle(active === 'signals' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'signals' && active != 'signals' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionSignals}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[12].icon}
            alt="signals Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'signals' || active === 'signals'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'signals' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[12].name}
            </div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'signals' && active != 'signals'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[12].name}
          </div>
        </Link> */}

        {/* just to fix the css issue*/}

        {/* <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            borderBottom: '0px',
            ...applyStyle(active === 'funds' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'funds' && active != 'funds' && !sidebarCollapse
            ),
            visibility: 'hidden',
          }}
        >
          <img
            src={SIDEBAR_CONTENTS[11].icon}
            alt="funds Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'funds' || active === 'funds'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'funds' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[11].name}
            </div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'funds' && active != 'funds' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[11].name}
          </div>
        </Link> */}
        {/* <div
          className="menu-itm active clickable"
          style={{
            borderBottom: '0px',
            ...applyStyle(active === 'retired' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'retired' && active != 'retired' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
            openAppRetiredApp();
          }}
          onMouseEnter={hoverFunctionRetired}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[12].icon}
            alt="retired Icon"
            style={{
              height:
                hoverItem === 'retired' || active === 'retired'
                  ? '50px'
                  : '40px',
            }}
          />
          {!sidebarCollapse && active === 'retired' && (
            <div className="sidebar-icon-label">
              {SIDEBAR_CONTENTS[12].name}
            </div>
          )}
          <div
            className="sidebar-icon-label1"
            style={{
              display:
                hoverItem === 'retired' && active != 'retired'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[12].name}
          </div>
        </div> */}
      </div>

      <div
        className="bottomboxx"
        style={{ height: isClose ? '2.5rem' : '55%' }}
      >
        <div className="tabss">
          <div className="tabss1">
            <div
              onClick={() => {
                // if (pathname.includes('vault')) {
                setSelectedTab('fund');
                // } else {
                //   setSelectedTab('air');
                // }
              }}
              style={{
                fontWeight:
                  selectedTab === 'air' || (selectedTab === 'fund' && !isClose)
                    ? '600'
                    : '100',
                // borderBottom:
                //   selectedTab === 'air' || (selectedTab === 'fund' && !isClose)
                //     ? '0.5px solid #464b4e'
                //     : '',
                opacity:
                  selectedTab === 'air' || (selectedTab === 'fund' && !isClose)
                    ? '1'
                    : '0.5',
              }}
            >
              <p>Actions</p>
            </div>
            <div
              onClick={() => setSelectedTab('tokens')}
              // className={selectedTab === 'tokens' && !isClose ? 'applyclass' : ''}
              style={{
                fontWeight:
                  selectedTab === 'tokens' && !isClose ? '600' : '100',
                // borderBottom:
                //   selectedTab === 'tokens' && !isClose
                //     ? '0.5px solid #464b4e'
                //     : '',
                opacity: selectedTab === 'tokens' && !isClose ? '1' : '0.5',
              }}
            >
              <p>Support</p>
            </div>
          </div>
          <div
            className="filterrr"
            onClick={(e) => {
              e.stopPropagation();
              if (!isCoinLoadingInAddAction) {
                if (coinActionEnabled) {
                  setCoinActionEnabled(false);
                  setCoinAction(['Menu']);
                } else if (addBankingEnabled) {
                  setAddBankingEnabled(false);
                } else {
                  toggleSettings(!openSettings);
                }
              }
              if (!isClose) {
                setIsClose(true);
              }
            }}
          >
            <img src={isClose ? close : settings} alt="settings" />
          </div>
        </div>
        {selectedTab === 'fund' ? (
          <div
            className="tabss-data"
            style={{ display: isClose ? 'none' : '' }}
          >
            {defaultData === 'default' ? (
              <div
                className="contennt"
                style={{
                  justifyContent: 'flex-start',
                  padding: '1.85rem 0.85rem',
                  height: '100%',
                  gap: '1.5rem',
                }}
              >
                <div
                  className="vault-contennt"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tempData?.length > 0) {
                      setDefaultData('fundCrypto');
                    }
                  }}
                  style={{
                    opacity: tempData?.length > 0 ? '1' : '0.5',
                    cursor: tempData?.length > 0 ? 'pointer' : 'not-allowed',
                    width: '100%',
                    padding: '1.5rem 0 1.5rem 1rem',
                  }}
                >
                  <div className="heading-vault">Fund Account With Crypto</div>
                  {/* <div className="subb">Add BTC, ETH, USDT, USDC & More</div> */}
                </div>
                <div
                  style={{
                    opacity: tempDataCad?.length > 0 ? '1' : '0.5',
                    cursor: tempDataCad?.length > 0 ? 'pointer' : 'not-allowed',
                    width: '100%',
                    padding: '1.5rem 0 1.5rem 1rem',
                  }}
                  className="vault-contennt"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tempDataCad?.length > 0) {
                      setSendBtnClicked('CAD');
                      setCoinActionEnabled(true);
                      setCoinType('fiat');
                      setSelectedCoin(tempDataCad[0]);
                      setCoinAction(['Add']);
                    }
                  }}
                >
                  <div className="heading-vault">Fund Account With Fiat</div>
                  {/* <div className="subb">Send CAD From Your Bank Account</div> */}
                </div>
                <div
                  className="vault-contennt"
                  onClick={() => {
                    setCoinType('tracker');
                  }}
                  style={{ width: '100%', padding: '1.5rem 0 1.5rem 1rem' }}
                >
                  <div className="heading-vault">Check Transaction Status</div>
                  {/* <div className="subb">Track Deposits & Withdrawals</div> */}
                </div>
              </div>
            ) : (
              <div
                className="contennt"
                style={{
                  justifyContent: 'flex-start',
                  padding: '0 0 0 1.85rem',
                  height: '100%',
                }}
              >
                {tempData?.map((e, i) => {
                  return (
                    <div
                      className="vault-contennt"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSendBtnClicked(e.coinSymbol);
                        setCoinActionEnabled(true);
                        setCoinType('crypto');
                        setSelectedCoin(e);
                        setCoinAction(['Add']);
                      }}
                    >
                      <div className="fundcrypto">
                        <img src={e?.coinImage} alt="btc" />
                        {e.coinName}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          // <div
          //   className="tabss-data"
          //   style={{ display: isClose ? 'none' : '' }}
          // >
          //   <div
          //     className="contennt"
          //     style={{ justifyContent: 'space-evenly', height: '100%' }}
          //   >
          //     <div
          //       className="tabss-data1"
          //       style={{ justifyContent: 'space-between' }}
          //     >
          //       <div style={{ display: 'flex', alignItems: 'center' }}>
          //         <div className="profile-icon">
          //           <img
          //             src={profileImg ? profileImg : guest}
          //             alt="dgplogo"
          //             style={{
          //               marginLeft: '0px',
          //               height: '40px',
          //               width: '40px',
          //             }}
          //           />
          //         </div>
          //         <div
          //           style={{
          //             paddingLeft: '7px',
          //             fontSize: '20px',
          //             fontWeight: 700,
          //           }}
          //         >
          //           {name ? name : username}
          //         </div>
          //       </div>
          //       <div
          //         className="verificationStatus"
          //         onClick={() => {
          //           window.open(
          //             'https://in.sumsub.com/idensic/l/#/sbx_uni_2hSenyE0Msz9cyjI',
          //             '_blank'
          //           );
          //         }}
          //       >
          //         Not Verified
          //       </div>
          //     </div>

          //     <div style={{ overflowY: 'scroll', height: '32vh' }}>
          //       <div className="tabss-data-custom">
          //         <div>Email:</div>
          //         <div>{email}</div>
          //       </div>
          //       <div className="tabss-data-custom">
          //         <div>Country:</div>
          //         <div>--</div>
          //       </div>
          //       <div className="tabss-data-custom">
          //         <div>State:</div>
          //         <div>--</div>
          //       </div>
          //       <div className="tabss-data-custom">
          //         <div>City:</div>
          //         <div>--</div>
          //       </div>
          //       <div className="tabss-data-custom">
          //         <div>Street:</div>
          //         <div>--</div>
          //       </div>
          //       <div className="tabss-data-custom">
          //         <div>Postal Code:</div>
          //         <div>--</div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          ''
        )}
      </div>
    </>
  );
};
