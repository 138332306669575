import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Vault from './pages/Vault';
import Portfolio from './pages/Portfolio';
import MobileApps from './pages/MobileApps';
import IcedIndex from './pages/IcedIndex';
import { BankContext } from './context/Context';
import Earnings from './pages/Earnings';
import NetWorthPage from './pages/NetWorthPage';
import IceMechineMobile from './pages/IceMechineMobile';
import MoneyMarkets from './pages/MoneyMarkets';
import BondOverview from './pages/BondOverview';
import Options from './pages/Options';
import DesktopApps from './pages/DesktopApps';
import DesktopAppDetail from './pages/DesktopAppDetail';
import WhatIsAssets from './pages/WhatIsAssets';
import WhatIsAssetsIoPlayList from './pages/WhatIsAssetsIoPlayList';
import AdminLogin from './pages/AdminLogin';
import InvestmentVaults from './pages/InvestmentVaults';
import GetStartedPage from './pages/GetStartedPage';
import VaultsPage from './pages/VaultsPage';
// import VaultsPage1 from './pages/VaultsPage1';
import MetaverseAppsPage from './pages/MetaverseAppsPage';
import TradeStreamPage from './pages/TradeStreamPage';
import MarketsPage from './pages/MarketsPage';
import MarketsPage1 from './pages/MarketsPage1';
import InvestorPage from './pages/InvestorPage';
import PreRegisterMobile from './pages/PreRegisterMobile';
// import LoginPage from './pages/Login/index';
import LoginPage from './pages/Login/Login';
import { useAppContextDetails } from './context/AppContext';
import Registration from './pages/Registration';
import FirstPage from './pages/Registration/pages/FirstPage';
import HomePage from './pages/Registration/pages/HomePage';
import ShareTokenPage from './pages/ShareToken';
import TaxesPage from './pages/TaxesPage';
import { Banking } from './pages/Banking';
import Malls from './pages/Malls';
import MallProduct from './components/MallProduct/MallProduct';
import CoachesPage from './pages/CoachesPage';
import StreamPage from './pages/StreamPage';
import EducationPage from './pages/EducationPage';
import TravelPage from './pages/TravelPage';
import { Toaster } from 'react-hot-toast';
import AffiliatePageR from './pages/AffiliatePageFromRetired';
import AcademyPage from './pages/AcademyPage';
import SwapPage from './pages/SwapPage';
import AiIndex from './pages/AiIndexPage';
import Tellers from './pages/TellersPage';
import Staking from './components/Investment/Staking';
import MallsIndex from './pages/AiIndexSuitesPage';
import Funds from './pages/Funds';
import SettingsPage from './pages/Settings';
import SuperAdminReset from './pages/SuperAdminReset';
import Login from './pages/Login/Login';
import Socially from './pages/Socially';
import SharesPage from './pages/SharesPage';
import MarketInfo from './pages/market'
function Routes() {
  const { refreshPage, email } = useContext(BankContext);
  const { appLogo, websiteTitle, websiteDescription } = useAppContextDetails();

  useEffect(() => {
    const favIcon = document.getElementById('fav-icon');
    favIcon.href = appLogo;
  }, [appLogo]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteDescription} />
      </Helmet>

      <Toaster />
      <Switch>
        <Route exact path="/superadminreset" component={SuperAdminReset} />
        <Route
          exact
          path="/register"
          component={window.innerWidth > 600 ? HomePage : Login}
        />
        <Route exact path="/register/affiliate" component={FirstPage} />
        <Route exact path="/register/affiliate/:id" component={FirstPage} />
        <Route exact path="/register/pre-registered" component={FirstPage} />
        <Route
          exact
          path="/register/pre-registered/:id"
          component={FirstPage}
        />
        <Route exact path="/register/by-myself" component={FirstPage} />
        <Route exact path="/register/by-myself/:id" component={FirstPage} />
        <Route
          exact
          path="/iceAsset"
          component={Portfolio}
          key={refreshPage === 'portfolio'.toString()}
        />
        <Route
          exact
          path="/iceAssetMobile/:coin"
          component={IceMechineMobile}
          key={refreshPage === 'iceMecine'.toString()}
        />
        <Route
          exact
          path="/net-worth"
          component={NetWorthPage}
          key={refreshPage === 'networth'.toString()}
        />
        <Route
          exact
          path="/net-worth/:assetClass/:assetCoin/:liquidity"
          component={NetWorthPage}
          key={refreshPage === 'networth'.toString()}
        />
  
        
        <Route
          exact
          path="/assets/:coinParam"
          key={refreshPage === 'assets'.toString()}
        >
          <IcedIndex />
        </Route>
        <Route
          exact
          path="/legacymarkets"
          key={refreshPage === 'index'.toString()}
        >
          <IcedIndex />
        </Route>
        <Route exact path="/markets" component={MarketInfo} />

        <Route exact path="/market/:assetClassName" component={MarketsPage} />
        <Route exact path="/swap" component={SwapPage} />
        <Route exact path="/swap/:assetClassName" component={SwapPage} />
        <Route
          exact
          path="/market/:assetClassName/:coin"
          component={MarketsPage}
        />
        <Route exact path="/market" component={MarketsPage} />
        <Route
          exact
          path="/markets/:assetClassName/:coin"
          component={MarketInfo}
        />
        <Route exact path="/markets/:assetClassName" component={MarketInfo} />
        {/* <Route
          exact
          path="/shares/:assetClassName/:coin"
          component={MarketsPage1}
        /> */}
          <Route
          exact
          path="/shares/:assetClassName/:coin"
          component={MarketInfo}
        />
            <Route
          exact
          path="/shares/:assetClassName"
          component={MarketInfo}
        />
        <Route exact path="/shares" component={MarketInfo} />
        <Route exact path="/share/holdings" component={SharesPage} />
        <Route exact path="/malls" component={Malls} />
        {/* <Route exact path="/malls/:id" component={LoginPage} /> */}
        <Route exact path="/funds" component={Funds} />

        {/* <Route exact path="/malls/:id" component={MallProduct} /> */}

        <Route exact path="/investors" component={InvestorPage} />
        <Route
          exact
          path="/legacymarkets/:marketTab"
          key={refreshPage === 'bridge'.toString()}
        >
          <IcedIndex />
        </Route>
        <Route
          exact
          path="/legacymarkets/:marketTab/:coinSymbol"
          key={refreshPage === 'bridge'.toString()}
        >
          <IcedIndex />
        </Route>
        <Route exact path="/bonds" key={refreshPage === 'bonds'.toString()}>
          <IcedIndex activeTab={'bonds'} />
        </Route>
        <Route
          exact
          path="/globalPayments"
          key={refreshPage === 'globalPayments'.toString()}
        >
          <IcedIndex activeTab={'globalPayments'} />
        </Route>
        <Route exact path="/banking" component={Banking} />

        {email ? (
          <Route
            exact
            path="/app"
            key={refreshPage === 'networth'.toString()}
            component={NetWorthPage}
          />
        ) : (
          <Route
            exact
            path="/app"
            key={refreshPage === 'index'.toString()}
            component={IcedIndex}
          />
        )}
        {email ? (
          <Route
            exact
            path="/app/:assetClass/:assetCoin/:liquidity"
            key={refreshPage === 'networth'.toString()}
            component={NetWorthPage}
          />
        ) : (
          <Route
            exact
            path="/app/:assetClass/:assetCoin/:liquidity"
            key={refreshPage === 'index'.toString()}
            component={IcedIndex}
          />
        )}
        <Route
          exact
          path="/Terminal"
          component={Options}
          key={refreshPage === 'Terminal'.toString()}
        />
        <Route
          exact
          path="/Terminal/:from"
          component={Options}
          key={refreshPage === 'Terminal'.toString()}
        />
        <Route
          exact
          path="/Classrooms"
          component={WhatIsAssets}
          key={refreshPage === 'Classrooms'.toString()}
        />
        <Route
          exact
          path="/tradestream"
          component={TradeStreamPage}
          key={refreshPage === 'tradestream'.toString()}
        />
        <Route
          exact
          path="/taxes"
          component={TaxesPage}
          key={refreshPage === 'taxes'.toString()}
        />
        <Route
          exact
          path="/sharetoken"
          component={ShareTokenPage}
          key={refreshPage === 'sharetoken'.toString()}
        />

        <Route
          exact
          path="/Classrooms/video/:videoId"
          component={WhatIsAssets}
          key={refreshPage === 'Classrooms'.toString()}
        />
        <Route
          exact
          path="/Classrooms/:any"
          component={WhatIsAssetsIoPlayList}
          key={refreshPage === 'Classrooms'.toString()}
        />
        <Route
          exact
          path="/metaverse"
          component={MetaverseAppsPage}
          key={refreshPage === 'metaverse'.toString()}
        />
        {/* <Route
          exact
          path="/affiliate"
          component={AffiliatePage}
          key={refreshPage === 'affiliate'.toString()}
        /> */}
        {/* <Route
          exact
          path="/affiliate/levels"
          component={Leveldata}
          key={refreshPage === 'affiliate'.toString()}
        /> */}
        <Route
          exact
          path="/react/coaching"
          component={CoachesPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/react/resources"
          component={StreamPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/react/education"
          component={EducationPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/react/affiliate"
          component={AffiliatePageR}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/react/travel"
          component={TravelPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/academy"
          component={AcademyPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/air"
          component={AiIndex}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/signals"
          component={Socially}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/settings"
          component={SettingsPage}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route exact path="/air/:id" component={MallsIndex} />
        <Route
          exact
          path="/vault/contracts/:id"
          component={Staking}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/tellers"
          component={Tellers}
          // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/vault/:assetClassParam?/:appCodeParam?/:coinParam?"
          component={VaultsPage}
          key={refreshPage === 'vaults'.toString()}
        />
        {/* <Route
          exact
          path="/assets/:assetClassParam?/:appCodeParam?/:coinParam?"
          component={VaultsPage1}
          key={refreshPage === 'assets'.toString()}
        /> */}
        <Route
          exact
          path="/investmentVault"
          component={InvestmentVaults}
          key={refreshPage === 'investmentVault'.toString()}
        />
        <Route
          exact
          path="/earning"
          component={Earnings}
          key={refreshPage === 'earn'.toString()}
        />
        <Route
          exact
          path="/vault/:type/:action"
          component={Vault}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/mobile-apps"
          component={MobileApps}
          key={refreshPage === 'mobileApps'.toString()}
        />
        <Route
          exact
          path="/desktopapps"
          component={DesktopApps}
          key={refreshPage === 'desktopApps'.toString()}
        />
        <Route
          exact
          path="/desktopapps/:os"
          component={DesktopAppDetail}
          key={refreshPage === 'desktopApps'.toString()}
        />
        <Route
          exact
          path="/moneyMarkets"
          component={MoneyMarkets}
          key={refreshPage === 'moneyMarkets'.toString()}
        />
        <Route exact path="/pre-register" component={PreRegisterMobile} />
        <Route exact path="/signup" component={GetStartedPage} />

        <Route exact path="/reset" component={GetStartedPage} />
        {/* <Route
          exact
          path="/bonds/:contractId"
          component={BondOverview}
          key={refreshPage === 'moneyMarkets'.toString()}
        /> */}
        <Route
          exact
          path="/adminLogin/:email/:token/:userEmail"
          component={AdminLogin}
        />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="*" component={LoginPage} />
        <Route component={NetWorthPage} />
      </Switch>
    </HelmetProvider>
  );
}

export default Routes;
