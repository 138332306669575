import React, { useEffect } from 'react';
import './settings.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/settingsFullLogo.svg';
import search from '../../static/images/malls/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import {
  DateSelectionDiv,
  InputDivs,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { uploadImageFunc } from '../../utils/imageUpload';
import uploading from '../../static/images/uploading.svg';
import dummyProfile from '../../static/images/dummyProfile.svg';
import Skeleton from 'react-loading-skeleton';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { InputDropdown } from '../../components/BuyBlocks/components/CreatePlanB';
import Eaconnetion from '../Funds/MyEAConnect';
import EA from '../Funds/EAPage';
const SettingsPage = () => {
  const {
    settingsTab,
    setSettingsTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
    settingsProfile,
    setSettingsProfile,
    authenticated2FA,
    setAuthenticated2FA,
    changePassword,
    setChangePassword,
    settingsProfileData,
    //settings
    settingsEmail,
    setSettingsEmail,
    settingsUsername,
    setSettingsUsername,
    allCountries,
    setAllCountries,
    //settings profile data
    settingsCountry,
    setSettingsCountry,
    settingsCountryDropdown,
    setSettingsCountryDropdown,
  } = useAppContextDetails();
  const {
    insideplanBCreate,
    setinsideplanBCreate,
    aiIndexDefaultSelected,
    setaiIndexDefaultSelected,
  } = useContext(planBContext);
  return (
    <Layout active="settings" className="settings" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="settings-navbar">
          <div className="settings-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-settings">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Search.."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          {/* <div
            className="settings-listBtn"
            onClick={() => {
              setinsideplanBCreate(!insideplanBCreate);
              // setSelectedDropDown(!selectedDropDown);
            }}
          >
            List Your Product
          </div> */}
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '45%' }}>
            <div
              className="each-option"
              style={{
                borderBottom:
                  settingsTab === 'Profile' ? '2px solid #9FD6DF' : '',
                fontWeight: settingsTab === 'Profile' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Profile')}
            >
              <div>
                <p>Profile</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  settingsTab === 'Identification' ? '2px solid #9FD6DF' : '',
                fontWeight: settingsTab === 'Identification' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Identification')}
            >
              <div>
                <p>Identification</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  settingsTab === 'Security' ? '2px solid #9FD6DF' : '',
                fontWeight: settingsTab === 'Security' ? '600' : '',
                pointerEvents: 'none',
              }}
              onClick={() => setSettingsTab('Security')}
            >
              <div>
                <p>Security</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  settingsTab === 'Notifications' ? '2px solid #9FD6DF' : '',
                fontWeight: settingsTab === 'Notifications' ? '600' : '',
                pointerEvents: 'none',
              }}
              onClick={() => setSettingsTab('Notifications')}
            >
              <div>
                <p>Notifications</p>
              </div>
            </div>

            <div
              className="each-option"
              style={{
                borderBottom: settingsTab === 'EA’s' ? '2px solid #9FD6DF' : '',
                fontWeight: settingsTab === 'EA’s' ? '600' : '',
              }}
              onClick={() => setSettingsTab('EA’s')}
            >
              <div>
                <p>EA’s</p>
              </div>
            </div>

            <div
              className="each-option"
              style={{
                borderBottom:
                  settingsTab === 'My Connected EA’s'
                    ? '2px solid #9FD6DF'
                    : '',
                fontWeight: settingsTab === 'My Connected EA’s' ? '600' : '',
              }}
              onClick={() => setSettingsTab('My Connected EA’s')}
            >
              <div>
                <p> My Connected EA’s</p>
              </div>
            </div>
          </div>
        </div>
        {changePassword ? <SettingsSidebar /> : ''}
        {settingsTab == 'Profile' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                <div className="heading">Basic Details</div>
                <ImageUploadDiv
                  setFunc={setSettingsProfile}
                  funcValue={settingsProfile}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="Email"
                    placeholderText="Email"
                    setFunc={setSettingsEmail}
                    funcValue={settingsEmail}
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    setFunc={setSettingsUsername}
                    funcValue={settingsUsername}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="First Name"
                    placeholderText="Username"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Upline"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Phone Number"
                    placeholderText="Phone Number"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div>
                {/* <div className="heading">Address Details</div>
                <div className="split-divs">
                  <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="State"
                    placeholderText="State"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <InputDivs
                  heading="Address "
                  placeholderText="Address "
                  // setFunc={setaidropdownRegLink}
                  // funcValue={aidropdownRegLink}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="City"
                    placeholderText="City"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Postal code"
                    placeholderText="Postal code"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div> */}
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Change Password</div>
                <div className="para">
                  Click here to change your password. You will need to verify
                  your email again to reset your password.
                </div>
                <div
                  className="btn"
                  onClick={() => setChangePassword('changepass1')}
                >
                  Change Password
                </div>
              </div>
              <div className="change-pass">
                <div className="title">
                  {authenticated2FA?.mfa_enabled ? 'Disable 2FA' : 'Enable 2FA'}
                </div>
                <div className="para">
                  {authenticated2FA?.mfa_enabled
                    ? 'You have already secured your account. Click here if you want to disable the 2FA from Google Authenticator.'
                    : 'For an additional layer of security you can enable 2 factor authentication via Google Authenticator.'}
                </div>
                <div
                  className="btn"
                  onClick={() => {
                    if (authenticated2FA?.mfa_enabled) {
                      setChangePassword('twofactorDisable1');
                    } else if (authenticated2FA?.mfa_enabled == false) {
                      setChangePassword('twofactorEnable1');
                    } else {
                    }
                  }}
                  style={{
                    display:
                      authenticated2FA?.mfa_enabled == true ||
                      authenticated2FA?.mfa_enabled == false
                        ? ''
                        : 'none',
                  }}
                >
                  {authenticated2FA?.mfa_enabled == true
                    ? 'Disable'
                    : authenticated2FA?.mfa_enabled == false
                    ? 'Enable'
                    : ''}
                </div>
              </div>
            </div>
          </div>
        ) : settingsTab == 'Identification' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                {/* <div className="heading">Basic Details</div>
                <ImageUploadDiv
                  setFunc={setSettingsProfile}
                  funcValue={settingsProfile}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="Email"
                    placeholderText="Email"
                    setFunc={setSettingsEmail}
                    funcValue={settingsEmail}
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    setFunc={setSettingsUsername}
                    funcValue={settingsUsername}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="First Name"
                    placeholderText="Username"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Upline"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Phone Number"
                    placeholderText="Phone Number"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div> */}
                <div className="heading">Address Details</div>
                <div className="split-divs">
                  {/* <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  /> */}

                  <InputDropdown
                    heading="Country"
                    mapValue={allCountries}
                    setFunc={setSettingsCountry}
                    funcValue={settingsCountry}
                    dropdown={settingsCountryDropdown}
                    dropdownFunc={setSettingsCountryDropdown}
                  />
                  <InputDivs
                    heading="State"
                    placeholderText="State"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <InputDivs
                  heading="Address "
                  placeholderText="Address "
                  // setFunc={setaidropdownRegLink}
                  // funcValue={aidropdownRegLink}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="City"
                    placeholderText="City"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Postal code"
                    placeholderText="Postal code"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Perform KYC</div>
                <div className="para">
                  If you plan on funding your forex Vaults in AiProWallet you
                  have to complete the KYC for our exchange partners.
                </div>
                <div
                  className="btn"
                  onClick={() =>
                    window.open(
                      'https://in.sumsub.com/idensic/l/#/sbx_uni_2hSenyE0Msz9cyjI',
                      '_blank'
                    )
                  }
                >
                  Sending CAD
                </div>
                <div
                  className="btn"
                  style={{ pointerEvents: 'none', opacity: '0.5' }}
                >
                  Sending EUR, GBP & USD
                </div>
              </div>
            </div>
          </div>
        ) : settingsTab == 'EA’s' ? (
          <EA />
        ) : settingsTab == 'My Connected EA’s' ? (
          <Eaconnetion />
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '88%' }}>
            <div className="malls">
              <div className="malls-content">
                <div
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                >
                  <Subscription />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SettingsPage;

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div className="imageUploadDiv" onClick={() => setSelectedDropDown('')}>
      <div className="heading">{heading}</div>
      <div className="imageDiv">
        <img
          src={funcValue ? funcValue : dummyProfile}
          alt="planBAccountPic"
          className="profileImg"
          htmlFor="profileUpdateImgPlanB"
        />
        {planBAccountPicUploading ? (
          <div className="overlayDiv">
            <img src={uploading} alt="uploading" className="uploadingimg" />
          </div>
        ) : (
          ''
        )}
      </div>
      <label htmlFor="profileUpdateImgPlanB" className="uploadFileDiv">
        <input
          className="uploadNewPicPlanB"
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Photo'}
        </div>
      </label>
    </div>
  );
};

const SettingsSidebar = () => {
  const {
    changePassword,
    setChangePassword,
    contentData,
    setContentData,
    changePasswordPin,
    setChangePasswordPin,
    changePasswordPass,
    setChangePasswordPass,
    changePasswordCnfmPass,
    setChangePasswordCnfmPass,
    changePasswordLoading,
    setChangePasswordLoading,
    message,
    setMessage,
    qrData2FA,
    setqrData2FA,
    qr2FAPin,
    setqr2FAPin,
    refreshSettings,
    setrefreshSettings,
  } = useAppContextDetails();

  const { email, accessToken } = useContext(BankContext);

  function nextStepChangePass() {
    setMessage('');
    switch (changePassword) {
      case 'changepass1':
        initiatePassReset();
        break;
      case 'changepass2':
        setChangePassword('changepass3');
        break;
      case 'changepass3':
        setChangePassword('changepass4');
        break;
      case 'changepass4':
        cnfmPassword();
        break;
      case 'changepass5':
        setChangePassword('');
        break;
      case 'changepassfailed':
        setChangePassword('');
        break;
      case 'twofactorEnable1':
        initiateEnable2FA();
        break;
      case 'twofactorEnable2':
        setChangePassword('twofactorEnable3');
        break;
      case 'twofactorEnable3':
        verify2FA();
        break;
      case 'twofactorEnable4':
        setChangePassword('');
        break;
      case 'twofactorDisable1':
        disable2FA();
        break;
      case 'twofactorDisable2':
        setChangePassword('');
        break;
      default:
        setChangePassword('');
        break;
    }
  }

  function initiateEnable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
        client_app: 'x3',
      })
      .then((response) => {
        if (response?.data?.status) {
          setqrData2FA(response?.data);
          setChangePassword('twofactorEnable2');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'initiateEnable2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
      });
  }
  function verify2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set/verify', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
        code: qr2FAPin ? qr2FAPin : '',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorEnable4');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabled');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabling failed');
      });
  }
  function disable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/disable', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorDisable2');
        } else {
          setChangePassword('twofactorDisableFailed');
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      });
  }

  function cnfmPassword() {
    if (changePasswordPass == changePasswordCnfmPass) {
      changePasswordFunc();
    } else {
      setMessage("Passwords don't match...");
    }
  }

  function changePasswordFunc() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/confirm', {
        email: email,
        code: changePasswordPin,
        newPassword: changePasswordPass,
      })
      .then((response) => {
        setMessage(response?.data?.message);
        if (response?.data?.status) {
          setChangePassword('changepass5');
        } else {
          setChangePassword('changepassfailed');
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'password change failed');
        setChangePassword('changepassfailed');
        setChangePasswordLoading(false);
      });
  }

  function initiatePassReset() {
    setChangePasswordLoading(true);

    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/request', {
        email: email,
        app_code: 'x3',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('changepass2');
          setChangePasswordLoading(false);
        } else {
          setMessage(response?.data?.message);
          setChangePasswordLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, 'Initiate forget password failed');
        setChangePasswordLoading(false);
      });
  }

  return (
    <div className="settingsSidebar">
      <div
        onClick={() => {
          // if (changePassword?.includes('changepass')) {
          //   setChangePassword('');
          // }else if (authenticated2FA?.mfa_enabled) {
          // }else{

          // }
          setChangePassword('');
        }}
      ></div>
      <div className="sidebarContainer">
        <div className="contentDiv">
          <div className="title">{contentData ? contentData?.name : ''}</div>
          <div className="content">
            {changePassword == 'changepassfailed' && message
              ? message
              : changePassword == 'twofactorEnable4' && message
              ? message
              : changePassword == 'twofactorDisableFailed' && message
              ? message
              : contentData
              ? contentData?.para
              : ''}
          </div>
          {contentData?.inputDiv == 'pin' ? (
            <OtpInput
              value={
                contentData?.name == 'Enable 2FA' ? qr2FAPin : changePasswordPin
              }
              onChange={
                contentData?.name == 'Enable 2FA'
                  ? setqr2FAPin
                  : setChangePasswordPin
              }
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle="otpInputWrapper"
              inputStyle="otpInput"
            />
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'password' ? (
            <div className="password">
              <input
                placeholder="Enter new password..."
                type="password"
                value={changePasswordPass}
                onChange={(event) =>
                  setChangePasswordPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'cnfmpassword' ? (
            <div className="password">
              <input
                placeholder="Enter confirm password..."
                type="password"
                value={changePasswordCnfmPass}
                onChange={(event) =>
                  setChangePasswordCnfmPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'qrdiv' ? (
            <div className="qRContainer">
              {qrData2FA && (
                <div className="qRsingleDiv">
                  <QRCode
                    className="qr-svg"
                    value={qrData2FA ? qrData2FA?.qrcodeValue : ''}
                    renderAs="svg"
                    size={300}
                  />
                </div>
              )}
              <div className="seperateBorder">OR</div>
              <div className="content">
                Enter this secret code into your Google Auth App.
              </div>
              <div className="qrcodes">
                {qrData2FA ? qrData2FA?.SecretCode : ''}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {message &&
          changePassword !== 'changepassfailed' &&
          changePassword !== 'changepass5' &&
          changePassword !== 'twofactorDisableFailed' &&
          changePassword !== 'twofactorEnable4' && (
            <div className="messageDiv">
              {message ? 'Note : ' + message : ''}
            </div>
          )}
        <div className="btn">
          {contentData?.btn1 && (
            <div
              onClick={nextStepChangePass}
              style={{
                pointerEvents: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : 'none',
                opacity: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : '0.7',
              }}
              className="topBtn"
            >
              <div>{contentData ? contentData?.btn1 : ''}</div>
              {changePasswordLoading && (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          )}
          <div
            onClick={() => (contentData ? setChangePassword('') : '')}
            className="bottomBtn"
          >
            {contentData ? contentData?.btn2 : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
